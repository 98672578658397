import XCircleIcon from '@heroicons/react/20/solid/XCircleIcon';
import {
  ActionFunction,
  json,
  LinksFunction,
  MetaFunction,
  redirect,
  LoaderFunctionArgs,
  TypedResponse,
} from '@remix-run/node';
import { Form, useLoaderData } from '@remix-run/react';
import { metaV1 } from '@remix-run/v1-meta';
import SiteBreadcrumbs from '../../components/site/SiteBreadcrumbs';
import { SiteLayout } from '../../components/site/SiteLayout';
import { Styles } from '../../components/Styles';
import { getAppName, getProductionHostname } from '../../lib/env';
import { authenticator } from '../../services/auth.server';
import {
  commitSession,
  destroySession,
  getSession,
} from '../../services/session.server';
import { SessionError } from '../../lib/sessionError.server';
import {
  requestAuthOptional,
  SetCookieHeaderName,
} from '../../lib/auth.server';
import { getAddonListingTrackedRedirect } from '../../lib/links';
import { FAQ, FAQs } from '../../components/account/FAQs';

export const LoginDestinationUrl = 'LoginDestinationUrl';

export const links: LinksFunction = () => {
  return [
    ...Styles.app,
    {
      rel: 'canonical',
      href: `https://${getProductionHostname()}/account/login`,
    },
  ];
};

export const meta: MetaFunction = (args) => {
  return metaV1(args, { title: `Login | ${getAppName()}`, robots: 'noindex' });
};

// Second, we need to export an action function, here we will use the
// `authenticator.authenticate method`
export const action: ActionFunction = async ({ request }) => {
  // we call the method with the name of the strategy we want to use and the
  // request object, optionally we pass an object with the URLs we want the user
  // to be redirected to after a success or a failure
  return authenticator.authenticate('google', request, {
    successRedirect: '/account',
    failureRedirect: '/account/login',
  });
};

type LoaderData = {
  error: Error | SessionError | undefined;
};

export const loader = async (
  args: LoaderFunctionArgs
): Promise<TypedResponse<LoaderData>> => {
  const { request } = args;
  const session = await getSession(request.headers.get('cookie'));
  const error: SessionError | undefined = session.get(
    authenticator.sessionErrorKey
  );
  if (error) {
    return json(
      { error },
      {
        headers: {
          'Set-Cookie': await destroySession(session),
        },
      }
    );
  }

  const url = new URL(request.url);
  const searchParamsDestinationURL = url.searchParams.get('r');
  const authAndSession = await requestAuthOptional(request);
  // are they already logged in?
  if (authAndSession) {
    const destinationUrl = searchParamsDestinationURL || '/account';
    return redirect(destinationUrl);
  }

  // store the destination, if there is one, in the session
  if (searchParamsDestinationURL) {
    session.flash(LoginDestinationUrl, searchParamsDestinationURL);
  }

  return json(
    {
      error: undefined,
    },
    {
      headers: {
        [SetCookieHeaderName]: await commitSession(session),
      },
    }
  );
};

const faqs: FAQ[] = [
  {
    question: 'Unable to find your account',
    answer: `If you're using our Google Sheets addon, please open the sidebar in Google Sheets, and click the Support button to automatically login to your account on our website`,
  },
];

export default function Login() {
  const data = useLoaderData<typeof loader>();

  return (
    <SiteLayout>
      <SiteBreadcrumbs
        pages={[{ title: 'Login', current: true, href: '/account/login' }]}
      />
      <h1>Login to your SyncWith account</h1>

      <p className="text-gray-700">
        If you already have an account, login below using Google. If you
        don&apos;t yet have an account please install our{' '}
        <a
          className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600 cursor-pointer"
          href={getAddonListingTrackedRedirect('login')}
          rel="nofollow"
        >
          Google Sheets add on
        </a>{' '}
        to get an account and get started.
      </p>
      {data && data.error && (
        <div className="rounded-md bg-red-50 p-4 my-8">
          <div className="flex">
            <div className="shrink-0">
              <XCircleIcon
                className="h-5 w-5 text-red-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800 m-0">
                Unable to login
              </h3>
              <div className="mt-2 text-sm text-red-700">
                <p>{data.error.message}</p>
                <p className="mt-2">
                  Please contact us using our{' '}
                  <a
                    className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600 cursor-pointer"
                    href="/support"
                  >
                    support page
                  </a>{' '}
                  for help.
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      <Form action="/auth/google" method="post" className="mt-4">
        <button>
          <img
            src="/images/btn_google_signin_dark_normal_web@2x.png"
            width="191"
            height="46"
          />
        </button>
      </Form>

      <FAQs faqs={faqs} />
    </SiteLayout>
  );
}
