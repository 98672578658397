export type FAQ = {
  question: string;
  answer: string;
};

type Props = {
  faqs: FAQ[];
};

export const FAQs = (props: Props) => {
  const { faqs } = props;
  return (
    <div className="lg:grid lg:grid-cols-3 lg:gap-8 mt-16">
      <div>
        <h2 className="text-3xl font-extrabold text-gray-900">
          Frequently asked questions
        </h2>
      </div>
      <div className="mt-12 lg:mt-0 lg:col-span-2">
        <dl className="space-y-12">
          {faqs.map((faq) => (
            <div key={faq.question}>
              <dt className="text-lg leading-6 font-medium text-gray-900">
                {faq.question}
              </dt>
              <dd className="mt-2 text-base text-gray-500">{faq.answer}</dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
};
